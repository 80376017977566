import { CLink } from '@/CLink';
import { Cart } from '@/shopify-client/cart/Cart';
import { ReactComponent as AccountIcon } from '@a/icons/account.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav } from './DesktopNavPrimitive';
import { MobNav } from './MobNav';
import { navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 12px;
    padding-bottom: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    border-bottom: 1px solid transparent;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    > div {
        ${flexSpace};
        max-width: 1772px;
        margin: 0 auto;

        > div {
            ${flexCenter};
            justify-content: flex-end;
        }
    }

    ${({ scrolled, theme }) =>
        scrolled &&
        css`
            background-color: ${theme.colors.blue1} !important;
            border-color: ${theme.colors.blue3};
        `};

    @media (min-width: 1280px) {
        padding-top: 18px;
        padding-bottom: 18px;
    }
`;

const HomeLink = styled(CLink)`
    display: flex;
    flex-shrink: 0;

    > div {
        transition: opacity 0.3s ease-in-out;

        @media (max-width: 399px) {
            width: 160px;
            height: 42px;
        }
    }

    :hover {
        div {
            opacity: 0.7;
        }
    }

    :focus-visible {
        div {
            opacity: 0.7;
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    display: none;

    @media (min-width: 840px) {
        display: block;
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :last-of-type > a {
            margin-right: 0 !important;
        }
    }
`;

const contentStyles = css`
    background-color: ${theme.colors.white};
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    top: calc((1.25rem * 1.5) + 20px);
    width: max-content;
    min-width: 224px;
    z-index: 100;
    left: 0;
    box-shadow: 0px 21px 38px rgba(217, 217, 217, 0.5);
    border: 0.5px solid ${theme.colors.gray2};

    > ul {
        max-height: calc(100vh - ((1.25rem * 1.5) + 16px) - 48px);
        overflow-y: auto;

        > li:last-of-type > a {
            border: none;
        }
    }
`;

const AccountLink = styled(CLink)`
    margin-right: 24px;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    :hover {
        > svg path {
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }

    :focus-visible {
        > svg path {
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }

    @media (min-width: 840px) {
        margin-left: 40px;
    }

    @media (min-width: 1024px) {
        margin-left: 64px;
        margin-right: 32px;
    }
`;

export const scrollToForm = () => {
    const element = document.querySelector('#contact');
    const y = element && element.getBoundingClientRect().top + window.scrollY - 112;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const LogoLink = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/layout/logo.png"
            alt="Smart AZ Minerals"
            width={182}
            height={48}
            loading="eager"
        />
    </HomeLink>
);

export const Nav = () => {
    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <LogoLink />
                    <div>
                        <DesktopNavMenu
                            menuStyles={menuStyles}
                            contentStyles={contentStyles}
                            links={navLinks}
                        />

                        <div>
                            <AccountLink to="/account/" aria-label="my account page">
                                <AccountIcon />
                            </AccountLink>
                            <Cart />
                            <MobNav />
                        </div>
                    </div>
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
